import React, { useEffect, useState } from 'react'
import imgOne from './img/01.jpg';
import imgTwo from './img/02.jpg';
import imgThree from './img/03.jpg';
import imgFour from './img/04.jpg';
import ImgNFT from './ImgNFT';

function GallerySlider({contract ,totalMinted }) {
    const [slides, getSlides] = useState([]);
    useEffect(() => {
        if (slides.length < 1) {
            setTimeout(() => {
                getSlides([...slides, { img: imgOne, desc: 'Some text about this art.', id: 243567 },
                { img: imgTwo, desc: 'Some text about this art.', id: 98742564 },
                { img: imgThree, desc: 'Some text about this art.', id: 978645 },
                { img: imgFour, desc: 'Some text about this art.', id: 3568 }]);
            }, 2000);
        }
    }, []);

    if (slides && slides.length > 0) {
        return (
            <>
                <ul className='listOfArts'>

              
                    {
                        totalMinted && Array(totalMinted +1).fill(0).map((_,i) =>
                            <li className='item-container' key={i}>
                                <ImgNFT tokenId={i} contract={contract} />
                               
                            </li>
                        )
                    }
                </ul>
            </>
        )
    } else {
        return (<> Gallery is loading, Please Wait..
        </>)
    }

}
export default GallerySlider;