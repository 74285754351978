import './App.scss';
import React, { useEffect, useState } from 'react';
import GallerySlider from './GallerySlider';
import logo from './logo.png';
import { ethers } from 'ethers';
import MintModal from './mintModal';
import CodcodilePunks from './CodcodilePunks.json'





  const contractAddress = '0xBF7b76653691fcE5417d04A58CA460e3e3A6A2f3';
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  
  const signer = provider.getSigner();

  const contract =  ethers.ContractFactory.getContract(contractAddress, CodcodilePunks.abi, signer);





function App() {
  const [address , setAddress] = useState();
  const [isModalOpen , openMedal] = useState(false);
  const [balance , setBalance] = useState();
  const [totalMinted, setTotalMinted] = useState();

  const cidId = 'QmVtrGEx1dMXBXdahAJP3SsngJiYap6jUJBzz5sjGj6QPt';
  

  const getCount = async () =>{
    const count = await contract.count();
    setTotalMinted(parseInt(count));
  }

  
  const getBalance = async () => {
    const [account] = await window.ethereum.request({method: 'eth_requestAccounts'});
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(account);
    setBalance(ethers.utils.formatEther(balance))
  }
  const connectWallet = () =>{
    if(window.ethereum) {
        window.ethereum.request({method: 'eth_requestAccounts'})
        .then(result=>{
            setAddress(result[0]);
        })
        getBalance();
    }else {
        console.log('Install Metamask')
    }
};


const mintToken = async ()=>{
  
  const connection = contract.connect(signer);
  const addr = connection.address;
  const metadataURI = `${cidId}/0${totalMinted+2}.json`;
  console.log({metadataURI})
  const result = await contract.payToMint(addr, metadataURI, {
    value: ethers.utils.parseEther('0.05'),
  });
  await result.wait();
};


useEffect(() => {
  getCount();

  const close = (e) => {
    if(e.keyCode === 27){
      openMedal(false)
    }
  }
  window.addEventListener('keydown', close)
return () => window.removeEventListener('keydown', close)
},[address, balance])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
        <span>NTF Artist</span>
      </header>
      
      <div className="collection-cointainer">
      <h1>New NFT's Collections</h1>
        <GallerySlider contract={contract} totalMinted={totalMinted} />
      </div>
      <button type="button" onClick={()=>openMedal(!isModalOpen)} className="mint-btn">Mint NFT Now</button>
     { isModalOpen && <MintModal mint={mintToken} address={address} balance={balance} isModalOpen={isModalOpen}  contactMetamask={connectWallet} />}
    </div>
  );
}

export default App;


