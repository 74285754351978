import react, { useState, useEffect } from 'react';
import hiddenImg from './hidden.jpg';



function ImgNFT({ tokenId, contract }) {
    const [isMinted, setIsMinted] = useState(false);
    const [metaData, setMetaData] = useState(null);

    const getMetadata = (url) => {
        fetch(url,{method: 'GET' , mode: 'cors',redirect: 'follow',referrerPolicy: 'no-referrer'})
        .then(async response => {
            if (response) {
                const data = await response.json();
                setMetaData(data);
            }
        });
    };
    const cidId = 'QmVtrGEx1dMXBXdahAJP3SsngJiYap6jUJBzz5sjGj6QPt';
    const metadataURI = `${cidId}/0${tokenId + 1}.json`;
    const imageURIReal = `https://gateway.pinata.cloud/ipfs/QmcMbB1PYWm1Vptyu8yWDzKJfY6dV57nakxqsuYSab39Zi/0${tokenId + 1}.jpg`;
    const imageURI = `0${tokenId}.jpg`;


    useEffect(() => {
        getMetadata(`https://gateway.pinata.cloud/ipfs/${metadataURI}`);
    }, [])

    useEffect(() => {
        getMintedStatus();
    }, [isMinted])

    const getMintedStatus = async () => {
        const results = await contract.isContentOwned(metadataURI);
        setIsMinted(results)
    }
    return (
        <>
            <img src={isMinted ? require(`./img/${imageURI}`) : hiddenImg} alt="image nft" />
            <div className='artDescription'>
               {metaData &&  <span>
                {metaData.description}
                </span>}
                <button type="button" disabled>its Minted</button>
            </div>
        </>
    )
}

export default ImgNFT;