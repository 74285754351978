import React from 'react'
import imageHidden from './hidden.jpg';

 function MintModal({ isModalOpen, contactMetamask, balance, address, mint}) {
     
  return (
    <div className='layout-modal'>
        <div className='modal-container'>
            <div className='content'>
                <img src={imageHidden} alt="hidden img" />
                <p>Get an digital art by Y.vin Now!</p>
                { !address ? <button onClick={()=>contactMetamask()} className='mint-btn'>Contact</button> : 
                 <button onClick={()=>mint()} className='mint-btn'>Ready to Mint</button> 
                }
                {balance && 
               <div className='your-details-account'>
                    <span className='title'>Your can buy NFT, your balance is:</span>
                    <span className='your-balance'>
                        {balance}
                    </span>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default MintModal;
